<template>
  <!-- <ftx-dialog
    :dialog="dialog"
    position="standard"
    className="dialog-xl"
    :maximized="false"
    persistent
    :onHide="onHide"
  >
    <template v-slot:DialogContent> -->
  <div
    :class="[
      'pwaInstaller ',
      show ? 'show' : '',
      isDialog ? 'dialog fullscreen' : '',
    ]"
  >
    <div class="pwaBackdrop fixed-full"></div>
    <q-card class="simpleDialog q-pa-none">
      <q-card-section class="installAppContent q-pa-none">
        <q-btn
          unelevated
          no-caps
          round
          color="grey-3"
          text-color="grey-9"
          icon="close"
          size="sm"
          class="absolute-top-right q-mr-sm q-mt-sm"
          @click="hideDialog(false)"
          v-if="!hideClose"
        />
        <div class="installAppTop">
          <!-- <Logo width="80px" height="80px" /> -->
          <q-img
            :src="logoURL"
            spinner-color="primary"
            spinner-size="26px"
            contain
            style="max-width: 80px; max-height: 80px"
          />
        </div>
        <div class="installAppText">
          <div
            class="text-subtitle1 text-weight-bold word-break-word line-height-normal q-pb-sm"
          >
            {{ pwaAppName }}
          </div>
          <div>
            Install this application on your home screen for quick and easy
            access when you're on the go.
          </div>
          <div class="q-mt-md installDirection">
            Just tap
            <SvgIcon color="blue" size="24px" :icon="browserType.icon" />
            then
            {{
              browserType.type?.includes('android')
                ? '"Add to Home Screen" or "Install"'
                : '"Add to Home Screen"'
            }}
          </div>
        </div>
        <div class="installAppBottom" v-if="!hideClose">
          Already Installed?
          <q-btn
            unelevated
            no-caps
            flat
            dense
            color="primaryOnBody"
            label="Don't show it again"
            padding="0"
            class="text-underline q-ml-sm"
            style="margin-top: -3px"
            @click="hideDialog(true)"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
  <!-- </template>
  </ftx-dialog> -->
</template>

<script>
import { Platform } from 'quasar'

export default {
  name: 'InstallationInstructions',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: 'InstallationInstructions',
    }
  },
  computed: {
    logoURL() {
      return this.businessConfigurations?.pwaImageUrl || this.theme?.logoURL
    },
    browserType() {
      let obj = { type: '', icon: 'more-vert' }
      if (Platform?.is?.mobile) {
        if (Platform?.is?.ios) {
          if (Platform?.is?.firefox) {
            //iOS firefox
            obj = { type: 'iosFirefox', icon: 'ios-firefox' }
          } else if (Platform?.is?.safari) {
            //iOS Safari
            obj = { type: 'iosSafari', icon: 'ios-share' }
          } else if (Platform?.is?.chrome) {
            //iOS chrome
            obj = { type: 'iosChrome', icon: 'ios-share' }
          } else if (Platform?.is?.opera) {
            obj = { type: 'iosOpera', icon: 'ios-firefox' }
          }
        } else if (Platform?.is?.android) {
          if (Platform?.is?.firefox) {
            //Android FireFox
            obj = { type: 'androidFirefox', icon: 'more-vert' }
          } else if (Platform?.is?.chrome) {
            //iOS chrome
            obj = { type: 'androidChrome', icon: 'more-vert' }
          } else if (Platform?.is?.opera) {
            obj = { type: 'androidOpera', icon: 'more-vert' }
          }
        }
      }
      return obj
    },
    pwaAppName() {
      return this.businessConfigurations?.pwaAppName
        ? `Install ${this.businessConfigurations.pwaAppName}`
        : `Install ${this.businessConfigurations.businessName}`
    },
  },
  methods: {
    hideDialog(doNotShowAgain = false) {
      this.$store.commit('common/UPDATE_STATE', {
        key: 'showPWAInstallPopup',
        val: false,
      })

      doNotShowAgain &&
        this.$store.commit('persistedLocal/SET_PWA_POPUP_DENIED', true)
    },
  },
}
</script>

<style scoped>
.pwaInstaller:not(.dialog) {
  background: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.12);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.pwaInstaller:not(.dialog) .simpleDialog {
  background: none;
}
.pwaInstaller:not(.dialog) .installAppContent .q-img {
  background: #fff;
}
.pwaInstaller:not(.dialog) .installDirection {
  padding: 8px 16px;
  background: #fff;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.pwaInstaller:not(.dialog) .pwaBackdrop {
  display: none;
}
.pwaInstaller.dialog {
  display: none;
  z-index: 999999;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.dialog .pwaBackdrop {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.pwaInstaller.dialog .simpleDialog {
  max-width: 355px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0) scale(0);
  transform-origin: center;
  transition: all 0.3s ease-in-out;
}
.pwaInstaller.dialog.show {
  display: block;
}
.pwaInstaller.dialog.show .pwaBackdrop {
  opacity: 1;
}
.pwaInstaller.dialog.show .simpleDialog {
  transform: translate3d(-50%, -50%, 0) scale(1);
}
@media (max-width: 374px) {
  .pwaInstaller.dialog .simpleDialog {
    max-width: 300px;
  }
}
</style>
