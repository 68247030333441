var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'pwaInstaller ',
    _vm.show ? 'show' : '',
    _vm.isDialog ? 'dialog fullscreen' : '',
  ]},[_c('div',{staticClass:"pwaBackdrop fixed-full"}),_c('q-card',{staticClass:"simpleDialog q-pa-none"},[_c('q-card-section',{staticClass:"installAppContent q-pa-none"},[(!_vm.hideClose)?_c('q-btn',{staticClass:"absolute-top-right q-mr-sm q-mt-sm",attrs:{"unelevated":"","no-caps":"","round":"","color":"grey-3","text-color":"grey-9","icon":"close","size":"sm"},on:{"click":function($event){return _vm.hideDialog(false)}}}):_vm._e(),_c('div',{staticClass:"installAppTop"},[_c('q-img',{staticStyle:{"max-width":"80px","max-height":"80px"},attrs:{"src":_vm.logoURL,"spinner-color":"primary","spinner-size":"26px","contain":""}})],1),_c('div',{staticClass:"installAppText"},[_c('div',{staticClass:"text-subtitle1 text-weight-bold word-break-word line-height-normal q-pb-sm"},[_vm._v("\n          "+_vm._s(_vm.pwaAppName)+"\n        ")]),_c('div',[_vm._v("\n          Install this application on your home screen for quick and easy\n          access when you're on the go.\n        ")]),_c('div',{staticClass:"q-mt-md installDirection"},[_vm._v("\n          Just tap\n          "),_c('SvgIcon',{attrs:{"color":"blue","size":"24px","icon":_vm.browserType.icon}}),_vm._v("\n          then\n          "+_vm._s(_vm.browserType.type?.includes('android')
              ? '"Add to Home Screen" or "Install"'
              : '"Add to Home Screen"')+"\n        ")],1)]),(!_vm.hideClose)?_c('div',{staticClass:"installAppBottom"},[_vm._v("\n        Already Installed?\n        "),_c('q-btn',{staticClass:"text-underline q-ml-sm",staticStyle:{"margin-top":"-3px"},attrs:{"unelevated":"","no-caps":"","flat":"","dense":"","color":"primaryOnBody","label":"Don't show it again","padding":"0"},on:{"click":function($event){return _vm.hideDialog(true)}}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }