import { render, staticRenderFns } from "./InstallationInstructions.vue?vue&type=template&id=5576ebc4&scoped=true"
import script from "./InstallationInstructions.vue?vue&type=script&lang=js"
export * from "./InstallationInstructions.vue?vue&type=script&lang=js"
import style0 from "./InstallationInstructions.vue?vue&type=style&index=0&id=5576ebc4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5576ebc4",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QImg});
